import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listSurveys, deleteSurvey } from "../../api/survey";
import { createExternalToken } from "../../api/auth";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { roles } from "../../utils/constants";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import "./index.css";
import { v4 as uuid } from "uuid";
import { Select, MenuItem } from "@mui/material";

import TextField from "@mui/material/TextField";

export default function Test() {
  const [surveys, setsurveys] = useState([]);
  const [loading, loadingSet] = useState(false);
  const [error, errorSet] = useState("");
  const [role, setRole] = useState("ADMIN");
  const [userId, setUserId] = useState(1);
  const [instanceId, setInstanceId] = useState("1234");
  const [registrationId, setRegistrationId] = useState(uuid().substring(0, 8));
  let navigate = useNavigate();

  const handleSSO = async (role, id, route, duplicateSurveyId = null) => {
    // localStorage.clear();
    const { accountId, token } = await createExternalToken(
      role,
      id,
      route,
      duplicateSurveyId,
      instanceId,
      registrationId,
      userId
    );
    navigate(`/sso/?accountId=${accountId}&token=${token}`);
  };

  const handleDeleteSurvey = async (id) => {
    const { deleted } = await deleteSurvey(id);
    if (deleted) setsurveys(surveys.filter((s) => id !== s.surveyId));
  };

  useEffect(() => {
    const getSurveys = async () => {
      try {
        loadingSet(true);
        const surveyList = await listSurveys(
          process.env.REACT_APP_TEST_ACCOUNT_ID
        );
        if (surveyList.error) errorSet(surveyList.error);
        if (Array.isArray(surveyList)) setsurveys(surveyList);
      } catch (e) {
        errorSet(e.message || "Error Fetching Surveys");
      } finally {
        loadingSet(false);
      }
    };
    getSurveys();
  }, []);

  if (loading) return <div>...loading</div>;

  return (
    <div className="test-root">
      <div className="survey-table-header">
        <h3>Surveys</h3>
        <IconButton
          size="large"
          aria-label="Edit Survey"
          color="success"
          onClick={() => handleSSO(role, null, "builder")}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </div>
      <div>
        Role:
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={role}
          label="role"
          onChange={(e) => setRole(e.target.value)}
        >
          {Object.values(roles).map((i) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: ".5rem",
        }}
      >
        <TextField
          id="reg-id"
          label="registraion-id"
          value={registrationId}
          onChange={(e) => setRegistrationId(e.target.value)}
        />
        <TextField
          id="instance-id"
          label="instance-id"
          value={instanceId}
          onChange={(e) => setInstanceId(e.target.value)}
        />
        <TextField
          id="user-id"
          label="user"
          value={userId}
          type="number"
          InputProps={{ inputProps: { min: 1, max: 50 } }}
          onChange={(e) => setUserId(e.target.value)}
        />
      </div>
      <span style={{ color: "red" }}>{error}</span>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {surveys.map((survey) => (
            <tr key={survey.surveyId}>
              <td>{survey.survey?.title || "Title Not Found."}</td>
              <td>{new Date(survey.createdAt).toLocaleString("en-US")}</td>
              <td>{new Date(survey.updatedAt).toLocaleString("en-US")}</td>
              <td>
                <IconButton
                  onClick={() => handleSSO(role, survey.surveyId, "survey")}
                  size="large"
                  aria-label="Take Survey"
                  color="inherit"
                >
                  <ArrowForwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleSSO(role, survey.surveyId, "builder")}
                  size="large"
                  aria-label="Edit Survey"
                  color="info"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Admin Survey"
                  color="info"
                  onClick={() => handleSSO(role, survey.surveyId, "results")}
                >
                  <SupervisorAccountIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Duplicate Survey"
                  color="error"
                  onClick={() =>
                    handleSSO(role, null, "builder", survey.surveyId)
                  }
                >
                  <ContentCopyIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Delete Survey"
                  color="error"
                  onClick={() => handleDeleteSurvey(survey.surveyId)}
                >
                  <DeleteIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <a href={`${process.env.REACT_APP_API_URL}/docs`}>Docs</a>
      </div>
    </div>
  );
}
